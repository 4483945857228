<div class="container-login-register">
  <div class="img-display"><app-auth-image class="displayed-img" [imageName]="'bg-portrat-2.jpg'"></app-auth-image></div>
  <div class="login-form">
    <div class="form-container">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <fieldset>
          <legend>
            Log in <span [ngClass]="{'spin': spinning}">{{ currentEmoji }}</span>
          </legend>
          <p>Please, type in your Email - Username and Password</p>
          <div class="form-group">
            <label for="username">Username:</label>
            <input type="text" class="form-control" id="orderForm_tb_email" formControlName="username" placeholder="Username">
          </div>
          <div class="form-group">
            <label for="password">Password:</label>
            <input type="password" class="form-control" id="orderForm_tb_password" formControlName="password" placeholder="Password">
          </div>
          <button type="submit" class="login-button" [disabled]="!loginForm.valid" id="orderForm_btn_login_KTM">Log in <i class="arrow-right-icon"></i></button>
        </fieldset>
        <div class="links mt-3">
          <a href="#">Forgot password?</a><br>
          <a href="#">Remote Access Single User Login</a>
        </div>
      </form>
    </div>
  </div>
</div>
